.w3rcontainer{
   border: 1px solid #cccfdb;
   border-radius: 2px;
} 
.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: black;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: red;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}