* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  background-color: #333;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
}

.logo a {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #333;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 90px;
  left: 0;
  z-index: 999;
  display: none;
}

.menu ul {
  list-style: none;
  text-align: center;
}

.menu ul li {
  margin-top: 20px;
}

.menu ul li a {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;
}

.menu.open {
  display: flex;
}

.menu-icon {
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 24px;
  position: absolute;
  top: 28px;
  right: 20px;
}

@media (min-width: 768px) {
  .menu {
    flex-direction: row;
    height: auto;
    position: static;
    background-color: transparent;
  }
  .menu ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .menu ul li {
    margin: 0 15px;
  }
  .menu-icon {
    display: none;
  }
}