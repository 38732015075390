@charset "UTF-8";
/*!
* Start Bootstrap - Coming Soon v6.0.6 (https://startbootstrap.com/theme/coming-soon)
* Copyright 2013-2022 Start Bootstrap
* Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-coming-soon/blob/master/LICENSE)
*/
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #000000;
  --bs-primary: #2a5555;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #000000;
  --bs-primary-rgb: 42, 85, 85;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 0, 0, 0;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 0, 0, 0;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: DM Sans, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Noto Color Emoji;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #000000;
  --bs-body-bg: #fff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

.btn-dark {
  color: #fff;
  background-color: #000000;
  border-color: #000000;
}
.btn-dark:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}
html {
  height: 100%;
}

.testRandom {
  height: 100%;
  position: relative;
  overflow: hidden;
}
.testRandom:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #2a5555;
  opacity: 0.7;
  z-index: 1;
}

.bg-video {
  position: absolute;
  top: 40%;
  left: 55%;
  min-width: 100vh;
  min-height: 100vh;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}

@media (pointer: coarse) and (hover: none) {
  .testRandom {
    background-size: cover;
  }

  .testRandom video {
    display: none;
  }
}
.masthead {
  position: relative;
  overflow: hidden;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.masthead:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.85);
}
.masthead .masthead-content {
  position: relative;
  max-width: 40rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.masthead .masthead-content h1,
.masthead .masthead-content .h1 {
  font-size: 2.5rem;
}
.masthead .masthead-content p {
  font-size: 1.2rem;
}
.masthead .masthead-content p strong {
  font-weight: 700;
}
.masthead .masthead-content .input-group-newsletter input {
  height: auto;
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
}
.masthead .masthead-content .input-group-newsletter button {
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: calc(1rem + 2px);
}

@media (min-width: 992px) {
  .masthead {
    height: 100%;
    width: 75vw;
    min-height: 0;
    padding-bottom: 0;
  }
  .masthead:before {
    transform: skewX(-9deg);
    transform-origin: top right;
  }
  .masthead .masthead-content {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 2rem;
    padding-right: 9rem;
  }
  .masthead .masthead-content h1,
  .masthead .masthead-content .h1 {
    font-size: 3.5rem;
  }
  .masthead .masthead-content p {
    font-size: 1.3rem;
  }
}
@media (min-width: 1200px) {
  .masthead {
    width: 65vw;
  }
}
.social-icons {
  position: relative;
  z-index: 2;
}
.social-icons .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 4rem;
  width: 4rem;
  border-radius: 100rem;
}

@media (min-width: 992px) {
  .social-icons {
    position: absolute;
    height: 100%;
    top: 0;
    right: 2.5rem;
    width: auto;
  }
}
